import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import logoSource from '../../images/logo.svg'

export const Header = () => (
  <header className="template-header">
    <div className="logo-ctn">
      <img className="logo" src={logoSource} alt="logo SexEmulator" />
    </div>
    <div className="nav-ctn">
      <button
        type="button"
        id="audio-toggle"
        className="audio-toggle ui-btn"
        aria-label="toggle sound on/off"
      >
        <svg className="icon sound-icon">
          <use xlinkHref="#sound-icon" />
        </svg>
      </button>
      <button
        className="ui-btn stats-btn"
        type="button"
        aria-label="statistics"
      >
        <span>Stats</span>
      </button>
    </div>
  </header>
)

export const ExpCount = () => {
  function getQueryVariable(variable) {
    if (typeof window !== 'undefined') {
      var vars = window.location.search.substring(1).split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    }
  }
  const queryUrl = getQueryVariable('actions')
  const totalActions = queryUrl !== false ? queryUrl : '5'
  return (
    <section className="exp-ctn ui-data-ctn">
      <div className="ui-part">
        <h3 className="ui-title">
          Skill level <span>1</span>
        </h3>
        <div className="exp-count">
          <span className="exp-value">0</span> / {totalActions}
        </div>

        <div className="progress-wrap">
          <div className="progress-ctn progress-lvlup">
            <div className="progress" />
          </div>

          <div className="fireworks" />
        </div>
        <div className="to-next">To LVL2</div>
      </div>

      <div
        className="lvl-up-el ui-data-ctn"
        data-layer='{"eventAction":"Click","eventLabel":"LVL-Up","eventCategory":"UI Component"}'
      >
        <div className="lvl-up-msg">
          <span>S</span>
          <span>k</span>
          <span>i</span>
          <span>l</span>
          <span>l</span>
          <span>&nbsp;</span>
          <span>u</span>
          <span>p</span>
          <span>!</span>
        </div>

        <div className="lvl-up-bonus">
          Click to <strong>level up!</strong>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(Header)
