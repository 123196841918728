import React from "react";

export const Inner = props => {
  const { className, children, noborder } = props;
  return (
    <div className={`inner${className ? " " + className : ""}`}>
      {!noborder && (
        <div className="corner corner-top">
          <svg className="icon border-icon">
            <use xlinkHref="#border-icon" />
          </svg>
          <svg className="icon border-icon">
            <use xlinkHref="#border-icon" />
          </svg>
        </div>
      )}
      {children}
      {!noborder && (
        <div className="corner corner-bottom">
          <svg className="icon border-icon">
            <use xlinkHref="#border-icon" />
          </svg>
          <svg className="icon border-icon">
            <use xlinkHref="#border-icon" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Inner;
