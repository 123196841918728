import "./localStoragePolyfill";
import React from "react";
import { Howl } from "howler";
import { getPrefixedPath } from "./config";

export const getLocal = lname => localStorage.getItem(lname);
export const setLocal = (lname, lvalue) => localStorage.setItem(lname, lvalue);
export const dom = el => document.querySelector(el);
export const domAll = el => document.querySelectorAll(el);

export const ModelAvatar = props => {
  const { className } = props;
  return (
    <div className={`avatar-model${className ? " " + className : ""}`}>
      <img className="char-img" alt="avatar" />
    </div>
  );
};

export const initScripts = () => {
  function loadScriptAsync(uri) {
    return new Promise((resolve, reject) => {
      try {
        const tag = document.createElement("script");
        tag.src = uri;
        tag.async = true;
        tag.onload = () => {
          resolve();
        };
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } catch (e) {
        reject(e);
      }
    });
  }

  let injectModernizr = (async () => {
    await loadScriptAsync(getPrefixedPath("js/modernizr-custom.js"));
  })();

  const insertScripts = new Promise((resolve, reject) => {
    try {
      injectModernizr
        .then(() => {
          loadScriptAsync(getPrefixedPath("/js/datalayer.js?v=1.0.1"));
        })
        .then(() => {
          loadScriptAsync(getPrefixedPath("/js/audioCtx.js"));
        })
        .then(() => {
          loadScriptAsync("https://gateway.sexemulator.com/widgets/session-widget/latest/session-widget.min.js");
        })
        .then(() => {
          loadScriptAsync(getPrefixedPath("/js/audio.js?v=1.0.1"));
        })
        .then(() => {
          loadScriptAsync(getPrefixedPath("/js/cmd.js?v=1.0.3"));
        })
        .then(() => {
          loadScriptAsync(getPrefixedPath("/js/steps.js"));
        })
        .then(() => {
          resolve();
        });
    } catch (e) {
      reject(e);
    }
  });

  insertScripts
    .then(() => {})
    .catch(err => {
      console.log("Something went wrong....", err);
    });
};

let googlestats = false;
export const googleanalytic = () => {
  if (!googlestats) {
    window.dataLayer.push({
      UA_CR: "UA-118335291-14",
      event: "UA_CRData"
    });
  }
  googlestats = true;
};

export const validateLocalStorage = () => {
  const dataLocal = {
    ethnicity: ethList[Math.floor(Math.random() * ethList.length)],
    hair: hairList[Math.floor(Math.random() * hairList.length)],
    boobs: boobsList[Math.floor(Math.random() * boobsList.length)],
    valueskills_points_spanking: parseInt(getRandomInt(40, 60)),
    valueskills_points_feet: parseInt(getRandomInt(40, 60)),
    valueskills_points_sucking: parseInt(getRandomInt(40, 60)),
    valueskills_points_anal: parseInt(getRandomInt(40, 60)),
    selected_model_name: nameList[Math.floor(Math.random() * nameList.length)]
  };

  Object.keys(dataLocal).forEach(item => {
    if (localStorage.getItem(item) === null) {
      localStorage.setItem(item, dataLocal[item]);
    }
  });
};

const selectBodyPart = () => {
  const ethn = getLocal("ethnicity");
  const hair = getLocal("hair");
  const boobs = getLocal("boobs");
  const myList = [
    dom(`.char-opt.ethn-${ethn}`),
    dom(`.${ethn}.hair-${hair}`),
    dom(`.${ethn}.boobs-${boobs}`)
  ];

  myList.forEach(el => {
    el.classList.add("selected");
  });
};
const setInputName = () => {
  const localName = localStorage.getItem("selected_model_name");
  const randomName = nameList[Math.floor(Math.random() * nameList.length)];
  let inputName;
  if (localName !== null) {
    inputName = localName;
  } else {
    inputName = randomName;
  }
  dom(".input-name").value = inputName;
};

export const initModel = () => {
  const getLocal = a => localStorage.getItem(a);
  const setLocal = (a, b) => localStorage.setItem(a, b);
  if (getLocal("ethnicity") === null) setLocal("ethnicity", "caucasian");
  if (getLocal("hair") === null) setLocal("hair", "black");
  if (getLocal("boobs") === null) setLocal("boobs", "small");

  const localName = localStorage.getItem("selected_model_name");
  const randomName = nameList[Math.floor(Math.random() * nameList.length)];
  if (!localName) localStorage.setItem("selected_model_name", randomName);

  document.documentElement.classList.add(`ethn-${getLocal("ethnicity")}`);

  window.trackingData.init({ offer_id: 6131, url_id: 18306 });

  validateLocalStorage();
  selectBodyPart();
  setInputName();
  pointRemaining();
};

export const clearSelected = () => {
  document.querySelectorAll(".selected").classList.remove("selected");
};

export const caps = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const totalPointAllow = 300;
export const valSkills = ["sucking", "spanking", "anal", "feet"];
export const ethList = ["caucasian", "asian", "black"];
export const hairList = ["blond", "red", "black"];
export const boobsList = ["small", "medium", "big"];
export const nameList = [
  "Mary",
  "Patricia",
  "Jennifer",
  "Linda",
  "Elizabeth",
  "Barbara",
  "Susan",
  "Jessica",
  "Sarah",
  "Margaret",
  "Karen",
  "Nancy",
  "Lisa",
  "Betty",
  "Dorothy",
  "Sandra",
  "Ashley",
  "Kimberly",
  "Donna",
  "Emily",
  "Carol",
  "Michelle",
  "Amanda",
  "Melissa",
  "Deborah",
  "Stephanie",
  "Rebecca",
  "Laura",
  "Helen",
  "Sharon",
  "Cynthia",
  "Kathleen",
  "Amy",
  "Shirley",
  "Angela",
  "Anna",
  "Ruth",
  "Brenda",
  "Pamela",
  "Nicole",
  "Katherine",
  "Samantha",
  "Christine",
  "Catherine",
  "Virginia",
  "Debra",
  "Rachel",
  "Janet",
  "Emma",
  "Carolyn",
  "Maria",
  "Heather",
  "Diane",
  "Julie",
  "Joyce",
  "Evelyn",
  "Joan",
  "Victoria",
  "Kelly",
  "Christina",
  "Lauren",
  "Frances",
  "Martha",
  "Judith",
  "Cheryl",
  "Megan",
  "Andrea",
  "Olivia",
  "Ann",
  "Jean",
  "Alice",
  "Jacqueline",
  "Hannah",
  "Doris",
  "Kathryn",
  "Gloria",
  "Teresa",
  "Sara",
  "Janice",
  "Marie",
  "Julia",
  "Grace",
  "Judy",
  "Theresa",
  "Madison",
  "Beverly",
  "Denise",
  "Marilyn",
  "Amber",
  "Danielle",
  "Rose",
  "Brittany",
  "Diana",
  "Abigail",
  "Natalie",
  "Jane",
  "Lori",
  "Alexis",
  "Tiffany",
  "Kayla"
];

export const pointRemaining = () => {
  return (dom(
    ".total-number-slider"
  ).textContent = `${getTotalPoint()}/${totalPointAllow}`);
};

export const setFinaleStats = () => {
  valSkills.forEach(obj => {
    const el = `#result_skills_points_${obj} `;
    const getItemParse = parseInt(
      localStorage.getItem(`valueskills_points_${obj}`)
    );
    dom(`${el}.progress`).style.width = `${getItemParse}%`;
    dom(`${el}.stat-value`).textContent = `${getItemParse}`;
  });
};

export const getTotalPoint = () => {
  let sum = 0;
  valSkills.forEach(obj => {
    sum += parseInt(localStorage.getItem(`valueskills_points_${obj}`));
  });
  return Math.min(sum, 300);
};

const setSelections = () => {
  const local__ethn = localStorage.getItem("ethnicity");
  const local__boobs = localStorage.getItem("boobs");
  const local__hair = localStorage.getItem("hair");
  document.querySelectorAll(".char-opt").forEach(el => {
    el.classList.remove("selected");
  });

  const a = setInterval(() => {
    dom(`.char-opt.ethn-${local__ethn}`).classList.add("selected");
    dom(`.${local__ethn}.boobs-${local__boobs}`).classList.add("selected");
    dom(`.${local__ethn}.hair-${local__hair}`).classList.add("selected");
    clearInterval(a);
  }, 250);
};

export const randomizeModel = () => {
  let num = 0;

  valSkills.forEach(obj => {
    num = parseInt(getRandomInt(35, 55));
    localStorage.setItem(`valueskills_points_${obj}`, num);
  });
  setFinaleStats();

  const mathFloor = el => el[Math.floor(Math.random() * el.length)];

  const _Ethn = mathFloor(ethList);
  const _Hair = mathFloor(hairList);
  const _Boobs = mathFloor(boobsList);
  const combine = _Ethn + "-" + _Hair + "-" + _Boobs;

  dom(".char-img").src = window.playlist[combine].img;

  // Make sure we remove all ethn-classname
  ethList.forEach(el => {
    document.documentElement.classList.remove("ethn-" + el);
  });

  // Re-apply the new className
  document.documentElement.classList.add("ethn-" + _Ethn);

  // Start new video with the new params
  const startvideo = setInterval(() => {
    window.cmd.loadData(combine);
    window.cmd.player.playScene("idle");
    clearInterval(startvideo);
  }, 100);

  localStorage.setItem("ethnicity", _Ethn);
  localStorage.setItem("hair", _Hair);
  localStorage.setItem("boobs", _Boobs);
  localStorage.setItem(
    "selected_model_name",
    nameList[Math.floor(Math.random() * nameList.length)]
  );

  setSelections();
};

export const stepSounds = new Howl({
  src: [
    "https://static.sexemulator.com/cr2/medias/sexemulator-v2/introduction.mp3"
  ],
  sprite: {
    welcome: [0, 7500],
    intensity: [7500, 2700],
    look: [10700, 3000],
    fantasy: [13700, 9000],
    onestep: [22700, 6800]
  }
});

export default nameList;
