import React from "react";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";
import Inner from "./inner";

const ActionsPanel = () => (
  <div className="action-panel">
    <div className="swiper-container actions-ctn">
      <div className="swiper-wrapper actions" id="actions">
        <div className="swiper-slide action">
          <Inner>
            <div className="a-inner">
              <svg className="a-icon" />

              <div className="info-ctn">
                <p className="a-info" />
              </div>
            </div>
          </Inner>
        </div>
      </div>
    </div>
    <div className="ext-info info-ctn">
      <p className="a-info" />
    </div>

    <div className="disabled-note disabled info-ctn">
      <p>
        <FormattedMessage id={`not_skilled_enough`} />
      </p>
    </div>

    <div className="choose-action info-ctn" style={{ display: "none" }}>
      <p>
        <FormattedMessage id={`choose_action`} />
      </p>
    </div>
  </div>
);

export default injectIntl(ActionsPanel);
