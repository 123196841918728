import React from 'react'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { ModelAvatar, stepSounds } from '../../helpers'

const UiPart = props => {
  const { title } = props
  return (
    <div className="ui-part skills" id={`result_skills_points_${title}`}>
      <div className="skill-name">
        <FormattedMessage id={`skillname_${title}`} />
      </div>
      <div className="progress-ctn progress-stat">
        <div className="progress" style={{ width: '50%' }} />
      </div>
      <div className="stat-value">50</div>
    </div>
  )
}

const Character = () => {
  const handleClick = () => {
    document.documentElement.classList.add(
      'actions-out',
      'show-ending',
      'direct-form'
    ) // ending-1 -> current
    document.querySelector('.ending-1').classList.add('current')
    window.loadIframeUrl()
    window.cmd.player.mute()
    stepSounds.stop()
    stepSounds.play('onestep')
  }
  return (
    <section
      className="character-ctn ui-data-ctn"
      id={`character_name_selected`}
    >
      <div className="ui-part char-id">
        <ModelAvatar />
        <div className="char-info">
          <h3 className="ui-title">
            <FormattedMessage id={'selected_character'} />
          </h3>
          <div className="char-name js-name" id="my_custom_name" />
        </div>
      </div>
      <UiPart title="sucking" />
      <UiPart title="spanking" />
      <UiPart title="anal" />
      <UiPart title="feet" />
      <button
        className="change-btn  btn"
        type="button"
        data-layer='{"eventAction":"Click","eventLabel":"Change-Character","eventCategory":"UI Component"}'
        onClick={handleClick}
      >
        <FormattedMessage id={'create_my_profile'} />
      </button>
    </section>
  )
}

export default injectIntl(Character)
