import React from 'react'
import imgAdultOnly from '../../images/adultonly.png'

export const Icons = () => (
  <div className="svg-lib" style={{ display: 'none' }}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      xmlSpace="preserve"
    >
      <defs>
        <symbol id="border-icon" className="symbol-icon" viewBox="0 0 33.9 36">
          <path className="st0" d="M33.9,3h-8.6C12.9,3,2.9,13,2.9,25.4V36" />
        </symbol>

        <symbol
          id="sound-icon"
          className="symbol-icon"
          viewBox="0 0 23.37 18.06"
        >
          <path d="M13.61,0A9,9,0,0,0,6,4.19H2.54A2.54,2.54,0,0,0,0,6.73v4.6a2.54,2.54,0,0,0,2.54,2.54H6a9,9,0,0,0,7.62,4.19.65.65,0,0,0,.65-.65V.65A.65.65,0,0,0,13.61,0M5.71,12.57H2.54A1.24,1.24,0,0,1,1.3,11.33V6.73A1.24,1.24,0,0,1,2.54,5.49H5.71ZM13,16.73A7.77,7.77,0,0,1,7,13V5a7.77,7.77,0,0,1,6-3.69Z" />
          <path d="M16.81,4a.65.65,0,1,0-.74,1.07,4.84,4.84,0,0,1,0,8,.66.66,0,0,0-.16.91.65.65,0,0,0,.91.16A6.15,6.15,0,0,0,16.81,4" />
          <path d="M19.33,1.3a.65.65,0,1,0-.74,1.07,8.12,8.12,0,0,1,0,13.33.66.66,0,0,0-.16.91.65.65,0,0,0,.91.16,9.42,9.42,0,0,0,0-15.47" />
        </symbol>
      </defs>
    </svg>

    <svg className="icon-tits svg-icon" viewBox="0 0 70.3 39.9">
      <path d="M17.4 16.9c-0.5-2.3-2.9-3.1-5-2.6C10 15 8.6 17.5 8.7 19.9c0 0.3 0.1 0.5 0.2 0.7 0.6 2.3 3 3.4 5.3 2.6C16.7 22.3 18 19.6 17.4 16.9M13.3 20.2c-0.8 0.5-1.3 0.2-1.5-0.5 0.1-1 0.5-1.8 1.4-2.3 0.3-0.1 0.6-0.1 0.8-0.1 0.2 0 0.4 0.2 0.4 0.3C14.8 18.6 14.2 19.7 13.3 20.2M29.3 22.2c-4.4 6.2-13.4 10.4-20.4 6 -8.4-5.3-6.8-17.8 0-23.5 1.5-1.3-0.7-3.5-2.2-2.2C6.6 2.5 6.5 2.6 6.4 2.7 6.2 2.3 6 1.9 5.8 1.5 5.5 0.6 4.7 0.1 3.8 0.3 2.9 0.6 2.3 1.5 2.6 2.4c0.4 1 0.8 2 1.3 3C1.5 8.7 0.2 12.8 0 16.9c-0.2 5.4 2.5 10.4 6.7 13.6 0.8 0.6 1.6 1.1 2.4 1.5 -0.3 2.2 1.1 5.3 1.7 6.7 0.4 0.8 1.1 1.4 2.1 1.2 0.8-0.2 1.5-1.2 1.2-2.1 -0.4-0.9-0.7-1.9-1-2.9 -0.2-0.6-0.3-1.1-0.4-1.7 0-0.1 0-0.1 0-0.2 7.3 1.2 15.3-3.4 19.4-9.3C33.2 22.1 30.5 20.5 29.3 22.2M56.2 23c2.3 0.8 4.7-0.3 5.3-2.6 0.1-0.2 0.1-0.4 0.1-0.7 0-2.4-1.4-4.9-3.9-5.5 -2.1-0.6-4.5 0.4-5 2.6C52.2 19.4 53.6 22.1 56.2 23M55.9 17.4c0-0.1 0.2-0.3 0.4-0.3 0.2 0 0.5 0 0.8 0.1 0.9 0.5 1.4 1.2 1.4 2.2 -0.2 0.7-0.7 1-1.5 0.6C56.1 19.5 55.5 18.5 55.9 17.4M41 22.1c4.4 6.2 13.5 10.3 20.5 5.8 8.3-5.3 6.7-17.8-0.2-23.5 -1.6-1.3 0.7-3.5 2.2-2.2 0.1 0.1 0.2 0.2 0.3 0.3 0.2-0.4 0.4-0.8 0.5-1.3 0.3-0.9 1.1-1.4 2.1-1.2 0.8 0.2 1.5 1.2 1.2 2.1 -0.4 1-0.8 2-1.3 3 2.5 3.3 3.8 7.3 4 11.4 0.2 5.4-2.4 10.4-6.6 13.7 -0.8 0.6-1.6 1.1-2.4 1.5 0.3 2.2-1 5.3-1.6 6.7 -0.3 0.8-1.1 1.4-2.1 1.2 -0.8-0.2-1.5-1.2-1.2-2.1 0.4-0.9 0.7-1.9 1-2.9 0.2-0.6 0.3-1.1 0.4-1.7 0-0.1 0-0.1 0-0.2 -7.3 1.3-15.3-3.3-19.5-9.1C37.1 22.1 39.8 20.5 41 22.1" />
    </svg>

    <svg className="icon-spank svg-icon" viewBox="0 0 67.87 67.87">
      <path d="M1.93,17.94,24.55,40.55c2.32,2.32,16.35,5.69,16.35,5.69h0L61.43,66.76a3.77,3.77,0,0,0,5.33-5.33L46.24,40.9h0s-3.36-14-5.68-16.36L17.94,1.93C13.77-2.23,9.83,1,5.41,5.41S-2.23,13.77,1.93,17.94ZM36,29.11a2.06,2.06,0,0,1,1.68.58,3.69,3.69,0,0,1,.87,1.52A12.2,12.2,0,0,1,39,34.09,24.53,24.53,0,0,0,39.36,38a7.79,7.79,0,0,0,.75,2.06A8.18,8.18,0,0,0,38,39.31a30.15,30.15,0,0,0-3.68-.42c-1.27-.08-2.07-.15-2.39-.2a4.72,4.72,0,0,1-1.31-.33,2.84,2.84,0,0,1-.9-.6A2.15,2.15,0,0,1,29.11,36,2.81,2.81,0,0,1,30,34.2a3,3,0,0,1,1.9-.9,3.74,3.74,0,0,1,1.83.38,3.47,3.47,0,0,1-.42-1.78,3,3,0,0,1,.9-1.92A2.82,2.82,0,0,1,36,29.11ZM25.34,18.44A2.05,2.05,0,0,1,27,19a3.69,3.69,0,0,1,.87,1.52,11.69,11.69,0,0,1,.38,2.88,24.53,24.53,0,0,0,.41,3.92,8.05,8.05,0,0,0,.75,2.06,8,8,0,0,0-2.07-.76,27,27,0,0,0-3.68-.41c-1.28-.09-2.07-.16-2.39-.21A4.72,4.72,0,0,1,20,27.69a3,3,0,0,1-.91-.6,2.16,2.16,0,0,1-.64-1.73,2.77,2.77,0,0,1,.87-1.82,3,3,0,0,1,1.9-.91A3.84,3.84,0,0,1,23,23a3.3,3.3,0,0,1-.41-1.77,3,3,0,0,1,.89-1.92A2.82,2.82,0,0,1,25.34,18.44ZM14.67,7.77a2.05,2.05,0,0,1,1.69.58,3.8,3.8,0,0,1,.87,1.52,12.2,12.2,0,0,1,.38,2.88A27.13,27.13,0,0,0,18,16.67a7.92,7.92,0,0,0,.75,2.06A8.54,8.54,0,0,0,16.71,18,31.48,31.48,0,0,0,13,17.55c-1.27-.09-2.07-.15-2.39-.2A5.1,5.1,0,0,1,9.32,17a2.63,2.63,0,0,1-.9-.6,2.13,2.13,0,0,1-.64-1.73,2.76,2.76,0,0,1,.86-1.82,3,3,0,0,1,1.9-.91,3.61,3.61,0,0,1,1.83.38A3.45,3.45,0,0,1,12,10.56a3,3,0,0,1,.9-1.92A2.82,2.82,0,0,1,14.67,7.77Z" />
    </svg>

    <svg className="icon-squirt svg-icon" viewBox="0 0 61.91 56.18">
      <path d="M51.64,1.13C39.81-9,6.62,52.67,6.62,52.67,25.75,37.67,63.46,11.29,51.64,1.13Z" />
      <path d="M61.66,35.12c-3-8.24-36.28,13.08-36.28,13.08C38.91,46,64.71,43.36,61.66,35.12Z" />
      <path d="M32.54,54.61c6.1.93,17.53,3.32,17.35-.63S32.54,54.61,32.54,54.61Z" />
      <path d="M1.58,23.35c-3.95.2.75,17.35.75,17.35C3.22,34.59,5.53,23.14,1.58,23.35Z" />
      <path d="M16.88,6.13C10.69,4.2,8.2,33.22,8.2,33.22,13,24.34,23.07,8.06,16.88,6.13Z" />
    </svg>

    <svg className="icon-dance svg-icon" viewBox="0 0 43.65 76.49">
      <path d="M22.74,53.8,17.07,65.73V49.12l-.43.1L9.43,47.55a24.5,24.5,0,0,1,.88,6.17V73.11a3.38,3.38,0,0,0,6.76,0V68.87a3.37,3.37,0,0,0,1.54,1.49,3.44,3.44,0,0,0,1.45.33,3.38,3.38,0,0,0,3.06-1.93l6.6-13.87a3.38,3.38,0,0,0-.32-3.44L26.17,47,19,48.67Z" />
      <circle cx="16.64" cy="7.1" r="7.1" />
      <path d="M6.29,33.4a2,2,0,0,0,1.52.68,2,2,0,0,0,1.51-3.39L5,25.82l2.9-2.38A3.83,3.83,0,0,1,8,22.68a2.68,2.68,0,0,0,0,.4,4.1,4.1,0,0,0,7.32,2.54,3.94,3.94,0,0,1-4.71,2.09A12.58,12.58,0,0,1,11,31.34a7.85,7.85,0,0,1-.8,2.87h0l-.67,1.27a19.1,19.1,0,0,0-1,2L4.23,45.5l.4.09,12,2.79,12-2.79.4-.09-4.29-8.06c-.31-.7-.68-1.32-1-2l-.68-1.27h0a7.66,7.66,0,0,1-.8-2.87,19.16,19.16,0,0,1,.11-3.66,3.87,3.87,0,0,1-1.23.2,3.92,3.92,0,0,1-3.56-2.26,4.09,4.09,0,0,0,7.31-2.54,2.68,2.68,0,0,0,0-.4,4,4,0,0,0-.7-1.91c.1-.21.19-.4.29-.59a26.36,26.36,0,0,0,10-2.1,16.81,16.81,0,0,0,9.08-10.5A2,2,0,1,0,39.7,6.29c-1.5,4.42-3.91,6.68-6.92,8.1s-6.7,1.77-10.37,1.77a28.78,28.78,0,0,0-5.77-.53c-5.88,0-8.58,2.3-9.18,2.9h0L.74,24A2,2,0,0,0,.52,27Z" />
    </svg>

    <svg className="icon-pussy svg-icon" viewBox="0 0 43.93 55.65">
      <path d="M22.78,49.53c9-10.08,2.69-23.81.13-28.45a1.08,1.08,0,0,0-1.89,0c-2.56,4.64-8.82,18.37.12,28.45A1.1,1.1,0,0,0,22.78,49.53ZM23.2,26a1.24,1.24,0,1,1-2.47,0,1.24,1.24,0,0,1,2.47,0Z" />
      <path d="M38.91,2.56A3.84,3.84,0,0,0,35.28,0H8.65A3.85,3.85,0,0,0,5,2.56c-5.22,14.8-8.16,33.84.38,51a3.85,3.85,0,0,0,3.45,2.11H35.09a3.84,3.84,0,0,0,3.44-2.11C47.07,36.4,44.13,17.36,38.91,2.56ZM30.37,37.41,30.06,39a31.58,31.58,0,0,1-5.25,12A3.94,3.94,0,0,1,22,52.61,3.92,3.92,0,0,1,19.12,51a31.43,31.43,0,0,1-5.25-12l-.31-1.55a43.5,43.5,0,0,1,1-17.68,67.93,67.93,0,0,1,4.22-11.9A3.73,3.73,0,0,1,22,5.62,3.76,3.76,0,0,1,25.1,7.83a68.75,68.75,0,0,1,4.22,11.9A43.86,43.86,0,0,1,30.37,37.41Z" />
    </svg>

    <svg className="icon-oil svg-icon" viewBox="0 0 42.15 55.59">
      <path d="M14.7,29.89c-2.2,0-3.6,1.53-3.6,4s1.4,3.95,3.6,3.95,3.59-1.57,3.59-3.95S16.87,29.89,14.7,29.89Z" />
      <path d="M34.18,15.76A159,159,0,0,0,21.05,0h0C17.18,4.53,12.38,10,8.22,15.76,3.52,22.34,0,29.22,0,34.51a24.25,24.25,0,0,0,4.66,13C8,52,13,55.59,21.08,55.59S34.19,52,37.5,47.54a24.23,24.23,0,0,0,4.65-13C42.15,29.18,38.8,22.33,34.18,15.76ZM14.7,40.82a7,7,0,1,1,0-13.93,7,7,0,0,1,0,13.93Zm12.12-.13H23.5V29.87h3.32ZM25.17,28.75a1.8,1.8,0,1,1,0-3.58,1.8,1.8,0,1,1,0,3.58Zm7.36,11.94H29.22V26.35h3.31Z" />
    </svg>

    <svg className="icon-feet svg-icon" viewBox="0 0 28.44 73.11">
      <path d="M6.85,70.94c-1.37-1.8-2.23-6.83-2.31-9.11A94.46,94.46,0,0,0,2.38,43.09C1.36,37.87.73,32.53.06,27.23a11.31,11.31,0,0,1,.43-4.44c2-7,11.79-14.85,21.09-11.24,0,0,1.26.48,1.65.67,6.09,3.22,6.49,9.62,1.65,14.53-1.92,1.93-4,3.7-6.08,5.54-2.32,2.08-4.44,4.25-5,7.47a15.55,15.55,0,0,0,1.84,10.72A38.89,38.89,0,0,1,20.17,63.6c.67,4.83-2.08,9.23-6.87,9.5C11.45,73.14,8.19,72.71,6.85,70.94Z" />
      <path d="M21.23,2.48A3.09,3.09,0,0,1,24.1,0a3.18,3.18,0,0,1,3.06,1.84,8.46,8.46,0,0,1,.51,8c-.94,2.19-4.24,2.08-5.65,0S20.6,4.92,21.23,2.48Z" />
      <path d="M17.19,1.42a2.49,2.49,0,0,1,2,1.49A5.39,5.39,0,0,1,19,7.7c-.78,1.54-2.16,1.61-3.06.16a5.92,5.92,0,0,1-.67-4.75A1.86,1.86,0,0,1,17.19,1.42Z" />
      <path d="M11.92,3.46c1.42.32,2.44,2.79,1.85,4.64-.32,1.06-.83,1.92-2.16,1.69C10.11,9.51,9,7.15,9.49,5.31A2.34,2.34,0,0,1,11.92,3.46Z" />
      <path d="M7.4,7.35c1.1.2,1.93,1.77,1.73,3.26-.15.94-.31,2-1.57,2s-2.2-1.72-2-3.26C5.64,8.14,6.58,7.19,7.4,7.35Z" />
      <path d="M3.12,11c1.14.24,2,2.05,1.65,3.54-.23,1-.78,1.81-1.92,1.61S.88,14,1.28,12.42C1.47,11.59,1.87,10.73,3.12,11Z" />
    </svg>

    <svg className="icon-blowjob svg-icon" viewBox="0 0 65 29">
      <path d="M64.64,13.64c-.72-1-1.78-2.27-3.12-3.73-.13-.14-.26-.29-.41-.44-.84-.9-1.79-1.84-2.83-2.77l-.55-.48-.4-.35-.4-.33-.47-.37c-.57-.45-1.15-.89-1.76-1.3-.22-.16-.46-.31-.68-.46-.6-.39-1.23-.76-1.86-1.1L51.59,2A20.23,20.23,0,0,0,48,.58,15.28,15.28,0,0,0,44.14,0,30.71,30.71,0,0,0,33.45,1.68a3.08,3.08,0,0,1-1.9,0A30.71,30.71,0,0,0,20.86,0C11.47.17,3.21,9.88.36,13.64c-.66.87-.4,1.9.67,2.62l5.05,3.39,5.55,3.73c.35.24.7.46,1.07.68s.74.43,1.11.64A33,33,0,0,0,19.31,27c.43.14.87.27,1.31.39l1.05.28c.32.09.65.16,1,.23.46.11.92.2,1.37.29a45.57,45.57,0,0,0,5.63.7l.6,0,.55,0,.8,0h1.81c.29,0,.6,0,.89,0h.48l1.31-.09,1.43-.13.88-.11c1.35-.17,2.68-.41,4-.7l1-.24c.33-.09.67-.17,1-.27a35.15,35.15,0,0,0,5-1.84L50.61,25a28.56,28.56,0,0,0,2.76-1.62L64,16.26C65,15.54,65.3,14.51,64.64,13.64ZM27.48,13A30.11,30.11,0,0,1,38,13l6.88,2.33c-8.09,1.64-17.4,1.78-24.28.12Z" />
    </svg>

    <svg className="icon-ass svg-icon" viewBox="0 0 56.52 49.08">
      <path d="M51.15,2.87a1.38,1.38,0,0,0-.91-.75A80.59,80.59,0,0,0,26.33.21,64.13,64.13,0,0,0,12.91,2.62,1.35,1.35,0,0,0,12,3.7L11.75,5A7.55,7.55,0,0,1,7.83,10.7a15.64,15.64,0,0,0-5.66,5.5A15.38,15.38,0,0,0,0,24,15.07,15.07,0,0,0,15.08,39.11a18,18,0,0,0,3.08-.33,1.7,1.7,0,1,1,.67,3.33,28,28,0,0,1-3.75.34A20.2,20.2,0,0,1,11.16,42v1.66a1.37,1.37,0,0,0,.75,1.25,14.49,14.49,0,0,0,6,1.34,27.76,27.76,0,0,0,10.33-2V38.7a17.34,17.34,0,0,1-7.91-26,15.78,15.78,0,0,0-2.92-2.91,1.67,1.67,0,0,1,2-2.67,15.24,15.24,0,0,1,3.17,3,16.54,16.54,0,0,1,2-1.67A17.48,17.48,0,0,1,29.41,6a1.61,1.61,0,0,1,2.08,1.08A1.61,1.61,0,0,1,30.41,9.2a14.59,14.59,0,0,0-7,5,13.93,13.93,0,0,0,1.33,18.16,13.55,13.55,0,0,0,9.84,4.09,21.3,21.3,0,0,0,2.24-.17,1.68,1.68,0,1,1,.5,3.33,14.94,14.94,0,0,1-2.74.25,17.1,17.1,0,0,1-3-.25v5.25a7.26,7.26,0,0,0,2.58,2.42,23.23,23.23,0,0,0,7.41,1.66,16.39,16.39,0,0,0,7.92-.75A6.41,6.41,0,0,0,51.57,47a32.82,32.82,0,0,0,4.5-12.83,54.73,54.73,0,0,0,.08-13.33A68.39,68.39,0,0,0,51.15,2.87ZM43.32,37.36a1.25,1.25,0,0,1-.91.34A1.87,1.87,0,0,1,41,37a1.62,1.62,0,0,1,.42-2.25h.08a1,1,0,0,1,.42-.25,1.57,1.57,0,0,1,1.91.58A1.67,1.67,0,0,1,43.32,37.36Z" />
    </svg>
  </div>
)

export const IconAdultOnly = () => (
  <div className="symbole">
    <img src={imgAdultOnly} alt="Adult Only" />
  </div>
)

export default Icons
