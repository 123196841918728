if (typeof localStorage === "undefined") {
  global.localStorage = {
    _data: {},
    setItem: function(id, val) {
      return (this._data[id] = String(val));
    },
    getItem: function(id) {
      return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
    },
    removeItem: function(id) {
      return delete this._data[id];
    },
    clear: function() {
      return (this._data = {});
    }
  };
}

if (typeof cmd === "undefined") {
  global.cmd = {};
}
if (typeof cta === "undefined") {
  global.cta = {};
}
